.header {
    text-align: center;
}

.header-logo {
    height: 40vmin;
    pointer-events: none;
}
  
.logo {
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    float: left;
    // position: relative;
    .logo-title {
        margin: auto;
        color: white;
        position: absolute;
        // text-align: center;
    }

}
  